import { cx } from "class-variance-authority";

const ADDR = "ola@teatro.app";

export default function SupportEmail({ className }: any) {
  return (
    <a href={`mailto:${ADDR}`} className={cx("underline", className)}>
      {ADDR}
    </a>
  );
}
